@import "../src/sass/variables.scss";
@import "../src/sass/mixins.scss";
@import "../src/pages/UploadGlbPage/components/FormUpload/FormUpload.module.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
  width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: $white;
  @extend .formUpload;
  @include scroll-bar;
  .app {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    align-items: center;
    max-height: fit-content;
    max-height: fit-content;
    @include scroll-bar;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
