@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

#pageManIconPart {
  :global {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-image: url("../../../public/images/man.png");
    border-radius: 0px;
    flex-grow: 1;
    position: relative;
    @include background-img;

    @include mq($xlg) {
      padding: 15px;
      max-width: calc(100% - 15px);
      max-height: calc(100% - 25px);
    }

    @include mq($md) {
      padding: 10px;
      max-width: calc(100% - 10px);
      min-height: 70vh;
      max-height: calc(100% - 10px);
    }

    .glbLoading {
      position: absolute;
      width: 100%;
      height: 80vh;
      display: grid;
      place-items: center;
    }

    .glbError {
      position: absolute;
      width: 100%;
      height: 80vh;
      display: grid;
      place-items: center;

      .customize-empty-glb {
        justify-content: center;
      }
    }

    .icons {
      display: flex;
      justify-content: space-between;

      .iconBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;

        @include mq($md) {
          padding-bottom: 15px;
        }

        @include mqh($md) {
          gap: 15px;
          padding-bottom: 15px;
        }

        @include mqh($sm) {
          gap: 10px;
        }

        .SinglIcon {
          width: 70px;
          aspect-ratio: 1/1;
          height: auto;
          border-radius: 0%;

          @include mq($xlg) {
            width: 50px;
          }

          @include mq($lg) {
            width: 45px;
          }

          @include mq($lg) {
            width: 40px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      @include mq($lg) {
        justify-content: space-between;
      }

      .create {
        height: 60px;
        border-radius: 0px;
        background: transparent;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 49px;
        gap: 5px;
        mix-blend-mode: normal;
        cursor: pointer;

        @include mq($xlg) {
          padding: 0 30px;
        }

        @include mq($lg) {
          flex-grow: 1;
          padding: 0 10px;
        }

        @include mq($md) {
          height: 30px;
          padding: 0 20px;
        }

        .contain {
          position: absolute;
          inset: 0;
          border-radius: 0px;
          backdrop-filter: blur(14px);
          --webkit-backdrop-filter: blur(14px);
          background-color: rgba(0, 0, 0, 0.5);
        }

        .letters {
          font-size: 16px;
          color: $white;
          font-family: $poppinsLight;
          font-weight: normal;
          font-size: 16px;
          text-align: left;
          z-index: 2;

          @include mq($xlg) {
            font-size: 15px;
          }

          @include mq($lg) {
            font-size: 12px;
          }

          @include mq($md) {
            font-size: 14px;
          }
        }

        .img {
          height: 21px;
          width: 21px;
          z-index: 2;

          @include mqh($xls) {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
}