@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.sideNav {
  :global {
    min-height: 100%;
    background-color: transparent;
    border-radius: 12px;
    max-width: fit-content;
    min-width: fit-content;
    // position: fixed;
    // left: 15px;
    // top: 15px;
    // bottom:15px;
    max-width: fit-content;
    .content {
      min-width: 249px;
      max-width: 249px;
      background-color: $blue;
      padding: 15px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 100%;
      height: 100%;
      border-radius: 12px;
      @include mq($xlg) {
        padding: 10px;
        min-width: 200px;
        max-width: 200px;
      }
      @include mq($lg) {
        min-width: 180px;
        max-width: 180px;
      }
      @include mq($md) {
        padding: 10px;
        min-width: 60px;
        max-width: 60px;
      }
      @include mq($xls) {
        padding: 7px;
        min-width: 60px;
        max-width: 60px;
      }
      @include mqh($xls) {
        gap: 20px;
      }
      .imgeLogo {
        height: 60px;
        @include mq($xlg) {
          height: 50px;
          width: 35.06px;
        }
        @include mq($md) {
          height: 40px;
          width: 30.06px;
          margin-bottom: 20px;
        }
        @include mq($xls) {
          height: 30px;
          width: 20.06px;
        }
      }
      .item {
        width: calc(100% - 15px);
        display: flex;
        margin: 0;
        gap: 12px;
        border-radius: 25px;
        height: 50px;
        padding: 0;
        align-items: center;
        background-color: transparent;
        padding-left: 10.50228310502283%;
        cursor: pointer;
        text-decoration: none;
        @include mq($xlg) {
          height: 40px;
          gap: 10px;
        }
        @include mq($lg) {
          height: 30px;
          gap: 8px;
        }
        @include mq($md) {
          padding-left: 0;
          justify-content: center;
          align-items: center;
          gap: 0;
          width: 100%;
          aspect-ratio: 1/1;
          height: auto;
          border-radius: 50%;
        }
        .icon {
          cursor: pointer;
          position: relative;
          z-index: 2;
          max-height: 24px;
          max-width: 24px;
          @include mq($xlg) {
            max-height: 20px;
            max-width: 20px;
          }
          @include mq($lg) {
            max-height: 18px;
            max-width: 18px;
          }
          @include mq($xls) {
            max-height: 15px;
            max-width: 15px;
          }
        }
        .title {
          color: $background;
          position: relative;
          z-index: 2;
          font-family: $sf-pro-medium;
          cursor: pointer;
          @include font-size-sub-title;
          @include mq($md) {
            display: none;
          }
        }
      }
      .active {
        border-radius: 25px;
        mix-blend-mode: color-dodge;
        position: relative;
        @include transition;
        background: radial-gradient($purple 0%, rgba(232, 0, 182, 0) 100%);
        @include mq($md) {
          border-radius: 50%;
        }
        .activeBg {
          position: absolute;
          inset: 0;
          border-radius: 25px;
          z-index: 0;
          background: transparent;
          backdrop-filter: blur(30px);
          --webkit-backdrop-filter: blur(30px);
          background-color: rgba(255, 255, 255, 0.15);
          @include mq($md) {
            border-radius: 50%;
          }
        }
      }
      .nav_body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .dismissTitle {
          display: none;
        }
        .nav_items {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 7px;
        }
        .logoutBox {
          border-top: 1px solid $background;
          height: 20%;
          width: 100%;
          align-self: baseline;
          background-color: transparent;
          display: flex;
          align-items: center;
          padding: 0;
          .item {
            background-color: transparent;
            &:hover {
              background-color: transparent;
            }
            &:focus {
              background-color: transparent;
            }
          }
        }
      }
    }
    .reduceNavSize {
      min-width: 80px;
      max-width: 80px;
      align-items: center;
      .imgeLogo {
        height: 40px;
        width: 30.06px;
        margin-bottom: 20px;
      }
      .nav_body {
        .nav_items,
        .logoutBox {
          .title {
            display: none;
          }
        }
        .nav_items {
          .item {
            padding-left: 0;
            width: 100%;
            aspect-ratio: 1/1;
            height: auto;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0;
          }
          .active {
            mix-blend-mode: color-dodge;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            @include transition;
            background: radial-gradient($purple 0%, rgba(232, 0, 182, 0) 100%);
            padding: 5px;
            border-radius: 50%;
            .activeBg {
              position: absolute;
              inset: 0;
              border-radius: 50%;
              z-index: 0;
              background: transparent;
              backdrop-filter: blur(30px);
              --webkit-backdrop-filter: blur(30px);
              background-color: rgba(255, 255, 255, 0.15);
            }
          }
        }
      }
     
    }
  }
}
