@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.products {
  :global {
    @include pageBody;
    @include bodyChilds;
    min-height: 80vh;
    border-radius: 0px;

    .loading {
      min-width: calc(100vw - 30px);
      height: 70vh;
      display: grid;
      place-items: center;
      .MuiLinearProgress-root{
        width: 100%;
      }
    }

    .singleProductBox {
      width: calc(100% / 7 - 20px);
      border-radius: 0px;
      height: 333px;
      padding: 0;

      @include mq($xlg) {
        width: calc(100% / 6 - 15px);
        height: 300px;
      }

      @include mq($lg) {
        width: calc(100% / 5 - 15px);
        height: 270px;
      }

      @include mq($md) {
        width: calc(100% / 4 - 15px);
        height: 250px;
      }

    }

    .header {
      display: grid;
      align-items: center;
      gap: 10px;

      .title {
        color: $white;
        font-family: $sf-pro-medium;
      }

      .btns {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
      }

      .active {
        background-color: $opacityblack;
      }

      .btn {
        border-radius: 0px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        color: $white;
        font-family: $sf-pro-medium;
        position: relative;
        backdrop-filter: blur(10px);
        padding-left: 30px;
        padding-right: 30px;
        @include transition;
        cursor: pointer;

        @include mq($xlg) {
          width: 140px;
          height: 50px;
          font-size: 12px;
          border-radius: 0px;
        }

        @include mq($lg) {
          height: 40px;
          width: 120px;
          border-radius: 0px;
        }
      }
    }

    .products {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;

      @include mq($xxlg) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @include mq($xd-width+10px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @include mq($xlg) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      @include mq($lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include mq($xlg) {
        gap: 15px;
      }
    }
  }
}

.card {
  :global {
    height: 300px;
    background-color: $whiteDark;
    text-decoration: none;
    @include background-img;
    border-radius: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      display: flex;
      justify-content: space-between;

      .right {
        width: 40px;
        height: 40px;
        aspect-ratio: 1/1;
        display: grid;
        place-items: center;
        border-radius: 0%;
        background: transparent;
        backdrop-filter: blur(10px);
        --webkit-backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.15);
        padding: 0px;

        .icon {
          width: 34px;
          height: 34px;
          border-radius: 0%;
          aspect-ratio: 1/1;
          background: rgba(0, 0, 0, 0.5);
          display: grid;
          place-items: center;
        }
      }
    }

    .bottom {
      height: 40px;
      border-radius: 0px;
      background: transparent;
      /* Note: currently only Safari supports backdrop-filter */
      backdrop-filter: blur(10px);
      --webkit-backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.44);
      /* (plus shape's fill blended on top as a separate layer with 31% opacity) */
      font-family: $sf-pro-regular;
      font-weight: normal;
      font-size: pxToEm(14);
      text-align: left;
      color: $white;
      padding: 3px 3px 3px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        aspect-ratio: 1/1;
        border-radius: 0%;
        display: grid;
        place-items: center;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.left {
  :global {
    @include btnCard;
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(15px);
    background-color: rgba(0, 0, 0, 0.3);
    padding: 3px;
    width: auto;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    height: unset;

    .profileImg,
    .number {
      border-radius: 0%;
      background-color: $opacityblack;
      height: 34px;
      aspect-ratio: 1/1;
      width: auto;
      color: $white;
      font-family: $sf-pro-medium;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      @include mq($xlg) {
        font-size: 12px;
      }
    }

    .number {
      z-index: 0;
    }
  }
}