@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.emptyFilter{
  :global {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .bg_empty{
      min-height:  20vh;
      height: auto;
      margin-bottom: 20px;
    }
    .details{
      margin: 20px auto;
      max-width: 50%;
    }
    .btn{
      margin: 0 auto;
    }
    
  }
}
