@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.uploadGlb {
  :global {
    height: 100%;
    background-color: transparent;
    width: 100%;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 2fr 1.3fr;
    gap: 20px;

    .upload,
    .preview {
      background-color: $whiteDark;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      @include mq($xlg) {
        padding: 15px;
      }

      @include mq($md) {
        padding: 15px;
      }

      @include mqh($xls) {
        padding: 10px;
      }
    }
    .fitContent {
      height: fit-content;
      max-height: fit-content;
    }

    .upload {
      gap: 25px;

      @include mqh($xls) {
        padding: 15px;
      }

      .top_content {
        display: flex;
        flex-direction: column;
        gap: 7px;

        .title {
          font-family: $sf-pro-medium;
          font-size: 30px;
          text-align: left;
          color: $black;

          @include mq($xlg) {
            font-size: 25px;
          }

          @include mq($md) {
            font-size: 20px;
          }

          @include mqh($xls) {
            font-size: 16px;
          }
        }

        .sub_title {
          font-family: $sf-pro-medium;
          font-size: 30px;
          text-align: left;
          color: $opaqueBlack;
          @include font-size-sub-title;
        }
      }
    }

    .preview {
      padding: 20px;
      gap: 60px;
      display: flex;
      flex-direction: column;

      @include mq($xlg) {
        gap: 60px;
        padding: 15px;
      }

      @include mq($md) {
        gap: 60px;
        padding: 12px;
      }

      @include mqh($xls) {
        gap: 60px;
        padding: 10px;
      }

      .preview_img {
        background-color: transparent;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;

        @include mq($xlg) {
          gap: 8px;
        }

        @include mq($md) {
          gap: 5px;
        }

        .glb {
          flex-grow: 0;
          min-height: 50vh;
          max-height: 50vh;
        }

        .title {
          font-family: $sf-pro-medium;
          font-size: 22px;

          @include mq($xlg) {
            font-size: 18px;
          }

          @include mq($lg) {
            font-size: 16px;
          }

          @include mq($md) {
            font-size: 14px;
          }

          @include mq($xls) {
            font-size: 12px;
          }
        }

        .img {
          border-radius: 12px;
          flex-grow: 1;
          padding: 0;
          aspect-ratio: 1/1;
          height: auto;
          width: 100%;
          max-width: 100%;
          background-size: contain;

          @include mq($md) {
            background-size: contain;
            .preview_image {
              border-radius: 12px;
              margin: 0;
              width: 100%;
              height: auto;
            }
          }
          .placeholder {
            border-radius: 12px;
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: auto;
            height: 45vh;
          }
        }
        .placeholder {
          @include background-img;
          background-size: contain;
          background-position: center;
        }
        .userImage {
          min-width: 100%;
          width: 100%;
          max-width: 100%;
          aspect-ratio: 1/1;
          height: auto;
          border-radius: 50%;
          background-color: $background;
          @include background-img;
          object-fit: contain;
          border: 5px solid $blue;
        }
      }
    }
  }
}
