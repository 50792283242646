@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#bottomPageFonts {
  :global {
    position: absolute;
    max-width: 100%;
    padding: 0;
    margin: 0;
    inset: 70% 0 0 0;
    z-index: 0;
    background-color: transparent;
    font-family: $bogam;
    .fonts {
      width: 100%;
      padding: 0;
      padding-top: auto;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: #4AA4F8;
      opacity: 0.1;
      font-size: pxToVw(330.89);
      font-weight: bolder;
      height: 100%;     
      font-family: $bogam; 
    }
  }
}
