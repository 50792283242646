@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.fileUploadComponent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  :global {
    .glbupload {
      min-height: 10vh;
      max-height: fit-content;
      display: flex;
      justify-content: center;
      background-color: $background;
      border-radius: 12px;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      padding: 15px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%234936A2FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 25px;
      .dropzone {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
      }

      @include mq($md) {
        padding: 10px;
      }

      .imageBox {
        background-color: $blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include transition;
        height: 60px;
        aspect-ratio: 1/1;
        width: auto;
        margin-bottom: 15px;

        &:hover {
          background-color: lighten($color: $blue, $amount: 8);
        }

        @include mq($xlg) {
          height: 50px;
        }

        @include mq($lg) {
          height: 40px;
        }

        @include mqh($sm) {
          height: 35px;
          margin-bottom: 10px;
        }

        .icon {
          max-width: 80%;
          max-height: 80%;
        }
      }

      .instructions {
        .title{
            margin: 20px 0;
        }
        width: 50%;
        max-width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .bottomBox {
        border-radius: 25px;
        background-color: $blue;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 25px;
        min-width: 50%;
        max-width: fit-content;
        height: 45px;

        @include mq($xlg) {
          margin-top: 20px;
          height: 40px;
        }

        @include mq($lg) {
          margin-top: 18px;
        }

        @include mq($md) {
          margin-top: 15px;
          height: 35px;
        }

        .sizeHeightChilds {
          display: flex;

          @include mq($xlg) {
            height: 40px;
          }

          @include mq($md) {
            height: 35px;
          }
        }

        .imageBox {
          background-color: lighten($color: $blue, $amount: 21);
          margin: 0;
          max-height: 100%;
        }

        .name {
          color: $background;
          @include font-size-sub-title;
          display: flex;
          align-items: center;
          background-color: transparent;
          flex-grow: 1;
        }

        .btn {
          @include font-size-sub-title;
          flex-grow: 1;
        }

        .update {
          color: $blue;
        }
      }
    }
  }
}
