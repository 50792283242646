@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

#loginSignupPages {
  :global {
    position: absolute;
    inset: 0;
    padding: 15px;
    margin: 0;
    z-index: 2;
    overflow-x: hidden;
    background-color: $background;
    display: flex;
    max-height: fit-content;
    display: grid;
    grid-template-columns: 1.3fr 2fr;
    @include mq($xlg) {
      padding: 30px;
    }
    @include mq($lg) {
      padding: 2%;
    }
    @include mq($md) {
      padding: 15px;
    }
    @include mq($xls) {
      padding: 10px;
    }
    .form_part {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logoBox {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-height: fit-content;
        margin-bottom: 50px;
        @include mq($xlg) {
          margin-bottom: 35px;
        }
        @include mq($lg) {
          margin-bottom: 30px;
        }
        @include mq($md) {
          margin-bottom: 25px;
        }
        @include mqh($xls) {
          margin-bottom: 20px;
        }
      }
      .body_box{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }
      .body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: pxToVw(435);
        max-width: 70%;
        padding: 0;
        flex-grow: 1;
        max-height: fit-content;
        @include mq($xlg) {
          max-width: 70%;
        }
        @include mq($lg) {
          max-width: 90%;
        }
        .title {
          font-weight: bold;
        }
        .continue {
          margin: 28px 0 10px 0;
          @include mq($xlg) {
            margin: 20px 0 7px 0;
          }
          @include mq($lg) {
            margin: 15px 0 5px 0;
          }
          @include mqh($xls) {
            margin: 10px 0 3px 0;
          }
        }
        .medias {
          display: flex;
          gap: 10.57471264367816%;
          padding: 0;
          margin: 0;
          width: 100%;
          @include mq($xlg) {
            gap: 7.57471264367816%;
          }
          @include mq($lg) {
            gap: 6.57471264367816%;
          }
          @include mq($md) {
            gap: 5.57471264367816%;
          }
          @include mq($xls) {
            justify-content: space-between;
            gap: 3.57471264367816%;
          }
          .box {
            margin: 0;
            border-radius: 50%;
            background-color: $whiteDark;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            aspect-ratio: 1/1;
            width: auto;
            text-decoration: none;
            cursor: pointer;
            @include transition;
            @include mq($xlg) {
              height: 45px;
            }
            @include mq($lg) {
              height: 40px;
            }
            @include mq($md) {
              height: 40px;
            }
            @include mq($xls) {
              height: 30px;
            }
            &:hover {
              background-color: darken($color: $whiteDark, $amount: 7);
            }
            .image {
              max-height: 90%;
              aspect-ratio: 1/1;
              max-width: auto;
            }
          }
        }
        .lineBorder {
          width: 100%;
          display: flex;
          gap: 5px;
          padding: 0;
          margin: 30px 0;
          align-items: center;
          @include mq($lg) {
            margin: 25px 0;
          }
          @include mqh($xls) {
            margin: 20px 0;
          }
          @include mqh($sm) {
            height: 15px;
          }
          .line {
            flex-grow: 1;
            height: 1px;
            background-color: $gray;
          }
          .word {
            color: $gray;
            @include font-size-sub-title;
          }
        }
      }
    }
    //images slides box
    .images_part {
      border-radius: 34px;
      padding: 0;
      margin: 0;
      background-color: $blue;
      overflow: hidden;
      position: relative;
      min-height: calc(100vh - 15px);
      max-height: 100%;
      @include mq(1600px) {
        min-height: calc(100vh - 30px);
        max-height: 100%;
      }
      @include mq($lg) {
        min-height: calc(100vh - 20px);
        max-height: 100%;
      }
      @include mq($md) {
        min-height: calc(100vh - 15px);
        max-height: 100%;
      }
      @include mqh($xls) {
        min-height: calc(100vh - 10px);
        max-height: 100%;
      }
    }
  }
}
