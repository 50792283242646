@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.manageGlb {
  :global {
    background-color: $whiteDark;
    height: 100%;
    padding: 20px;
    border-radius: 12px;
    max-width: 100%;

    a {
      text-decoration: none;
    }

    @include mq($xlg) {
      padding: 15px;
    }

    @include mq($md) {
      padding: 10px;
    }

    .top_content {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-bottom: 20px;

      @include mq($md) {
        margin-bottom: 15px;
      }

      @include mq($xls) {
        margin-bottom: 10px;
      }

      .child {
        @include font-size-sub-title;
      }

      .title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;

        @include mq($xlg) {
          font-size: 25px;
        }

        @include mq($md) {
          font-size: 20px;
        }

        @include mq($xls) {
          font-size: 16px;
        }
      }

      .sub_title {
        font-family: $sf-pro-medium;
        font-size: 30px;
        text-align: left;
        color: $black;
        @include font-size-sub-title;
      }
    }

    .sort_box {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;

      .image {
        max-height: 12px;

        @include mq($md) {
          max-height: 10px;
        }
      }
    }

    .uploaded_glb {
      border-radius: 12px;
      flex-grow: 1;
      background-color: $background;
      padding: 15px 12px 15px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include mq($xlg) {
        padding: 15px 12px 15px 15px;
      }

      @include mqh($md) {
        padding: 10px;
      }

      .uploaded_glb_top {
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;

        @include mq($lg) {
          max-width: 80%;
        }

        @include mq($md) {
          max-width: 80%;
        }

        @include mq(650px) {
          max-width: 90%;
        }

        .searchBox {
          border-radius: 25px;
          background-color: $whiteDark;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 45px;
          padding: 0 30px;
          width: 26.81788079470199%;

          @include mq($xlg) {
            padding: 0 20px;
          }

          @include mq($md) {
            padding: 0 15px;
          }

          .search {
            cursor: pointer;

            @include mq($xlg) {
              height: 15px;
              width: 15px;
            }

            @include mq($md) {
              height: 13px;
              width: 12px;
            }
          }

          .search_input {
            border-radius: 25px;
            background-color: transparent;
            flex-grow: 1;
            height: 100%;
            border: none;
            box-shadow: none;
            color: $black;
            @include font-size-sub-title;

            &:focus {
              border: none;
              outline: none;
            }

            &::placeholder {
              color: $black;
              @include font-size-sub-title;
            }
          }
        }
      }

      .uploaded_glb_body {
        flex-grow: 1;

        @include mq(1185px) {
          overflow-x: scroll;
          @include scroll-bar;
          max-width: 90vw;
        }

        .title_box {
          display: grid;
          grid-template-columns: 0.2fr 2.5fr 2.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
          flex-flow: row nowrap;
          padding: 5px 0;

          @include mq($lg) {
            min-width: 1471px;
            max-width: 1471px;
          }

          .child {
            display: flex;
            justify-content: flex-start;
          }
        }

        .content_box {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @include mq($lg) {
            min-width: 1471px;
          }

          .row {
            display: grid;
            grid-template-columns: 0.2fr 2.5fr 2.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
            flex-flow: row nowrap;
            align-items: center;
            border-top: 1px solid $whiteDark;
            padding: 5px 5px;

            .published,
            .draft {
              border-radius: 25px;
              display: flex;
              max-height: 26px;
              justify-content: center;
              align-items: center;
              max-width: fit-content;
            }

            .published {
              background-color: $whiteDark;
            }

            .draft {
              background-color: #fffbe6;
            }
          }

          .child {
            display: flex;
            justify-content: flex-start;

            .d-flex {
              gap: 6px;
              display: flex;
              align-items: center;
            }
          }

          .glb {
            display: flex;
            gap: 15px;
            align-items: center;

            @include mq($xlg) {
              gap: 10px;
            }

            @include mq($lg) {
              gap: 5px;
            }

            .image {
              border-radius: 12px;
              max-width: 60px;
              max-height: 60px;
              gap: 10px;

              @include mq($xlg) {
                max-width: 45px;
                max-height: 45px;
              }

              @include mq($lg) {
                max-width: 35px;
                max-height: 35px;
              }

              @include mq($md) {
                max-width: 25px;
                max-height: 25px;
              }
            }
          }

          .preview,
          .edit,
          .delete {
            display: flex;
            margin: 0 0 0 15px;
            justify-content: center;
            padding: 10px 15px;
            flex-wrap: nowrap;
            flex-shrink: 2;
            @include font-size-sub-title;

            @include mq($xlg) {
              padding: 7px 10px;
              margin: 0 0 0 10px;
            }

            @include mq($lg) {
              padding: 5px 8px;
              margin: 0 0 0 8px;
            }

            @include mq($xls) {
              padding: 5px 5px;
              margin: 0 0 0 6px;
            }
          }

          .preview {
            border: 1px solid $whiteDark;
          }

          .edit {
            border: 1px solid $success;
          }

          .delete {
            border: 1px solid $purple;
          }
        }
      }
    }
  }
}