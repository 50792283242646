@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
#iconComponent {
  :global {
    .container {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      position: relative;
      display: grid;
      place-items: center;
      border-radius: 0%;
      background-color: transparent;
      cursor: pointer;
      * {
        transition: all 0.5s ease-in-out;
      }
      &:hover {
        .circle-1 {
          background: lighten(#4AA4F8, 0);
        }
      }
      .circle-1 {
        position: absolute;
        inset: 15%;
        background: lighten(#4AA4F8, 50);
        border-radius: 0%;
        z-index: 0;
      }
      .active {
        background: lighten(#4AA4F8, 0);
      }
      .circle-2 {
        position: absolute;
        inset: 0;
        z-index: 1;
        background: transparent;
        backdrop-filter: blur(11px);
        --webkit-backdrop-filter: blur(11px);
        background-color: $purpleOpacity;
        mix-blend-mode: color-dodge;
        border-radius: 0%;
      }
      .purple {
        mix-blend-mode: normal;
      }
      .btnIcons {
        position: absolute;
        z-index: 2;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0%;
        background-color: transparent;
        .Icon {
          max-width: 40%;
          max-height: 40%;
          z-index: 2;
        }
      }
    }
  }
}
