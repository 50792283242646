@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";

.formUpload {
  :global {
    flex-grow: 1;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 0;
    height: fit-content;
    @include mq($xlg) {
      gap: 18px;
    }
    @include mq($md) {
      gap: 15px;
    }
    @include mqh($xls) {
      gap: 10px;
    }
    .form_content {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: fit-content;
      @include mq($xlg) {
        gap: 10px;
      }
      @include mq($md) {
        gap: 7px;
      }
      @include mqh($xls) {
        gap: 5px;
      }
      .label {
        font-family: $sf-pro-medium;
        flex-shrink: 2;
        flex-wrap: nowrap;
        display: flex;
        flex-wrap: nowrap;
        @include font-size-sub-title;
        @include mq(820px) {
          font-size: 10px;
        }
        @include mq($md) {
          @include font-size-sub-title;
        }
      }
      .labelBox {
        display: flex;
        flex-wrap: nowrap;
        @include mq($md) {
          flex-direction: column;
        }
        .opacity {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          gap: 0;
          max-height: fit-content;
          .purple {
            color: $purple;
          }
        }
      }
      .input {
        border: none;
        box-shadow: none;
        border: 25px !important;
        background-color: $background !important;
      }
      .quil {
        border-radius: 12px;
        color: $black;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-family: $sf-pro-medium;
        border: none;
        .quilInput {
          border-radius: 12px;
          border: none;
          padding: 20px;
          color: $black;
          font-family: $sf-pro-regular;
          background-color: $background;
          min-height: 76px;
          max-height: fit-content;
          outline: none;
          @include mq($xlg){
            padding: 10px;
          }
          &:focus{
            border: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .buttons {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      .btn {
        border-radius: 25px;
        @include font-size-sub-title;
        text-transform: capitalize;
        width: fit-content;
        @include transition;
        padding: 10px 30px;
        @include mq($lg) {
          padding: 8 15px;
        }
        @include mqh($xls) {
          height: 30px;
          padding: 6 13px;
        }
      }
      .draft {
        background-color: $yellow;
        columns: $black;
      }
      .final {
        background-color: $blue;
        color: $background;
      }
    }
  }
}
